import React from 'react'
import { graphql } from 'gatsby'
import PageWrapper from '../components/pageWrapper'
import Card from '../components/card'
import PageDescription from '../components/pageDescription'
import HeroImage from '../components/heroImage'
import Other from '../components/other'
import Footer from '../components/footer'

const RegionPage = ({
  pageContext: { originalId },
  data: { datoCmsDestinationsPage: { regions, heroImage } }
}) => {
  const currentRegion = regions.find(region => region.originalId === originalId)
  const otherRegions = regions.filter(region => region.originalId !== originalId)

  const { name, description, destinations } = currentRegion

  return (
    <>
      <HeroImage media={heroImage}/>
      <PageWrapper>
        <PageDescription
          title={name}
          text={description}
        />
        {destinations.map(({ name, media, description, originalId }) => (
          <Card
            key={originalId}
            title={name}
            text={description}
            media={Array.isArray(media) ? media[0] : media}
          />
        ))}
        <Other
          title='Other Locations'
          to='/destinations'
          items={otherRegions}
        />
      </PageWrapper>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query regionPage {
    datoCmsDestinationsPage {
      heroImage {
        alt
        fluid(maxWidth: 1400, maxHeight: 630, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      regions {
        name
        description
        originalId
        media {
          id
          alt
          fluid(maxWidth: 300, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
        }
        destinations {
          name
          description
          originalId
          media {
            id
            alt
            fluid(maxWidth: 1000, maxHeight: 650, imgixParams: {
              fm: "jpg",
              auto: "compress,format",
              fit: "crop",
              crop: "faces"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        
      }
    }
  }
`

export default RegionPage
